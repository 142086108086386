@font-face {
  font-family: 'MartianMono';
  src: local('MartianMono'),
    url(./static/fonts/Martian_Mono.zip) format('truetype');
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #284245;
  font-family: 'MartianMono', sans-serif;
}

#root {
  .content {
    .chakra-checkbox__control {
      height: 2rem;
      width: 2rem;
    }
  }
}
